export const themes = [
    {
        name: 'LA LA Land',
        color1: '#323232',
        color2: "#434343",
        color3: "#dfe2eb"
    },
    {
        name: 'San Fran',
        color1: '#000861',
        color2: "#E1E4FF",
        color3: "#00075A"
    },
    {
        name: "J Tree",
        color1: '#F6E8AD',
        color2: "#63510A",
        color3: "#8AF28A"
    },
    {
        name: "Hollyweird",
        color1: '#D03838',
        color2: "#D1A138",
        color3: "#bbbbbb"
    },
    {
        name: "La Perla del Pacifico",
        color1: '#79EBDE',
        color2: "#072D28",
        color3: "#12776B"
    },
    {
        name: "El Barrio Bravo",
        color1: '#121212',
        color2: "#dcdcdc",
        color3: "#d0041d"
    },
];