<template>
    <section v-scrollanimation class="about-me mx-auto">
        <div class="about-me-inner flex flex-row items-center gap-[50px] relative">
            <div class="about-me-text">
                <div class="about-me-text-info text-right max-w-500px">
                    <div>
                        <div class="text-size-16px font-500 text-[var(--primary)] opacity-60">DEVELOPER </div>
                        <div class="lg:text-size-52px md:text-size-44px text-size-36px font-600 text-[var(--primary)] tracking-tight mt-10px">L. David Angulo</div>
                        <div class="text-size-52px md:text-size-44px text-size-36px font-600 text-[var(--lightestSlate)] tracking-tight">Developer and cyber security enthusiast.</div>
                    </div>
                    <div class="mt-30px">
                        <router-link to="/contact" class="bg-[var(--primary)] px-25px py-10px rounded-md text-[var(--background)] font-600 opacity-90 hover:opacity-100">
                            Get In Touch
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="about-me-photo z-3">
                <div class="wrapper">
                    <div class="photo" style="position: relative; overflow: hidden;">
                        <div class="photo-front"></div>
                        <img class="max-w-300px w-[100%] w-[300px]" loading="lazy" :src="image" alt="L. David Angulo - Profile Picture" />
                    </div>
                </div>
            </div>
            <SvgDecoration classNames="absolute md:visible invisible left-[-50px] bottom-[-40px] transform rotate-[-180deg] fill-[var(--dots)] opacity-80" :type="1" />
            <SvgDecoration classNames="absolute md:right-[-56px] md:visible invisible z-2 fill-[var(--svg-box)] opacity-100" :type="2" />
        </div>
        <div class="md:text-size-24px text-size-20px md:px-10px w-[100%] max-w-[770px] pt-100px md:leading-relaxed leading-relaxed select-none relative">
            <div class="mb-30px">
                My name is L. David Angulo. I enjoy all aspects of the software lifecycle. Over the years I have worked in development (both front end and back end), cyber security, networking, I.T., devops, SEO, project management, ecommerce management, and sales.
            </div>
            <div class="mb-30px">
                I am self taught and a very motivated and intense person. I love challenges and I love the thrill of solving business problems. Working together with a team of talented and focused people is one of the most magical experiences and I've had the honor of working with such people throughout the years.
            </div>
            <div>
                I take my programming work very seriously, as I consider writing good, clean, secure, and tested code as a spiritual experience. What a gift developers have to be able to impact large amounts of people. If you like to make the world a better place, then I would love to hear from you, so feel free to contact me at anytime and thanks so much for reading!
            </div>
            <SvgDecoration classNames="absolute right-[-150px] top-90 md:block hidden z-2 fill-[var(--dots)] opacity-80" :type="3" />
        </div>
    </section>
</template>
<script>
import SvgDecoration from './../SvgDecoration/SvgDecoration.vue';
import LDavidAngulo from './LDavidAngulo.jpg';

export default {
    components: { SvgDecoration },
    data: function () {
        return {
            image: LDavidAngulo,
        }
    },
    setup() {
        return {
            yearCount: () => {
                return new Date().getFullYear() - 2017;
            },
        };
    },
};
</script>
<style lang="postcss">
.about-me {
    @apply flex flex-col items-center min-h-[100vh] max-w-[900px] visible opacity-100 z-10 px-10px lg:mt-130px;}
    &.a-before-enter {
        @apply opacity-0 transform translate-y-[20px] duration-[0.3s] ease-in;
    }

    &.a-enter {
        @apply opacity-100 transform translate-y-0;
    }

    .about-me-photo {
        position: relative;
        z-index: 21;
    }

    .wrapper {
            @apply overflow-hidden rounded-lg;
    }

@media only screen and (max-width: 856px) {
    .about-me {
        .about-me-text-info {
            @apply text-center;
        }

        .about-me-inner {
            @apply flex flex-col gap-[50px];

            .about-me-photo {
                @apply order-1 self-center;
                z-index: 21;
            }
            .about-me-text {
                @apply order-2 self-center;
            }
        }
    }
}
</style>