export const skillList = [
];


export const mySkills = [
    {
        icon: "<i class='bx bxl-javascript' ></i>",
        tech: "JavaScript",
        des: "One of the most popular languages on the web, I have worked with many different frameworks and completed several projects.",
        yearStarted: 2019,
        tools: [
            {
                icon: `<i class="akar-icons gatsby-fill" ></i>`,
                text: 'Gatsby',
                des: `A static site generator. Super useful for blazing fast pages. Can be used in conjunction with a CMS such as WordPress.`,
            },
            {
                icon: `<i class='bx bxl-vuejs' ></i>`,
                text: 'Vue.js',
                des: `What this portfolio site is built with. Quick, simple, and easy to learn.`,
            },
            {
                icon: `<i class='bx bxl-nodejs'></i>`,
                text: 'NodeJS',
                des: `The backend for these modern day frameworks. `
            }
        ]
    },
    {
        icon: "<i class='bx bxs-file-css' ></i>",
        tech: "HTML/CSS",
        des: "I write semantic markup with accessibility in mind. I keep my CSS manageable and also slowly following BEM conventions/rules.",
        yearStarted: 2015,
        tools: [
            {
                icon: "<i class='bx bxl-sass' ></i>",
                text: "SASS",
                des: `CSS with superpowers. Sass is the most mature, stable, and powerful professional grade CSS extension language in the world.`
            },
            {
                icon: "<i class='bx bxl-html5' ></i>",
                text: "html5",
                des: `HTML5 is a markup language used for structuring and presenting content on the World Wide Web. It is the fifth and last major HTML version that is a World Wide Web Consortium recommendation. The current specification is known as the HTML Living Standard.`
            }
        ]
    },
    {
        icon: "<i class='bx bxl-php' ></i>",
        tech: "PHP",
        des: "Not the cleanest or easiest language, but still the most popular. ",
        yearStarted: 2018,
        tools: [
            {
                icon: '<i class="bx bxl-wordpress" ></i>',
                text: 'WordPress',
                des: 'World\'s most popular web framework. Fast, easy, and brings in the revenue. Can be the right tool for the right job.',
            },
            {
                icon: '<i class="bx bxl-wordpress" ></i>',
                text: 'WooCommerce',
                des: "World's most popular e-commerce framework. A well architected site can handle ten's of thousands of products with no problem.",
            },
        ]
    },
    {
        icon: "<i class='bx bxl-python' ></i>",
        tech: "Python",
        des: "My favorite language. I love to study data structures and algorithms with this language. Super useful for automating tasks and scraping.",
        yearStarted: 2019,
        tools: [
            {
                icon: `<i class='bx bxl-django' ></i>`,
                text: `Django`,
                des: `A web app framework that uses python for the backend. Fantastic.`
            },
            {
                icon: `<i class='bx bxl-python' ></i>`,
                text: `Cyber security`,
                des: `The go-to scripting language for automated pen-testing, cyber security auditing, and code testing. Lovely.`
            }
        ]
    },
    {
        icon: "<i class='bx bxl-chrome' ></i>",
        tech: "SEO",
        des: "This field includes content management, keyword research, link building, site architecture, and social media management.",
        yearStarted: 2016,
        tools: [
            {
                icon: `<i class='bx bxl-chrome' ></i>`,
                text: 'Screaming Frog',
                des: `A tool used to audit both internal and external links as well as overall site architecture.`,
            },
        ]
    },
    {
        icon: "<i class='bx bxl-amazon' ></i>",
        tech: "E-Commerce",
        des: "I can manage the entire lifecycle of e-commerce transactions, starting from the sourcing to materials and ending with integrations across multiple platforms, fraud prevention, and fulfillment solutions.",
        yearStarted: 2015,
        tools: [
            {
                icon: `<i class='bx bxl-ebay' ></i>`,
                text: 'eBay',
                des: `Although slowly losing market share, eBay is still the number 1 auction site in the world.`,
            },
            {
                icon: `<i class='bx bxl-amazon' ></i>`,
                text: 'Amazon',
                des: `The granddaddy of e-commerce. Love it or hate it, Amazon has dramatically raised the bar for e-commerce and shows no signs of slowing down.`,
            },
            {
                icon: `<i class='bx bxl-etsy'></i>`,
                text: 'Etsy',
                des: `Empowering the hobbies and crafts community, Etsy remains the go-to for custom and hard to find merchandise.`
            }
        ]
    },
];