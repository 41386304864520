<template>
    <section class="flex flex-col w-[100%] max-w-[1200px] visible opacity-100 z-10 px-10px mt-100px select-none mx-auto">
        <div class="text-center mb-40px">
            <h1 class="lg:text-size-52px md:text-size-44px text-size-36px font-800 text-[var(--primary)] tracking-tight mt-10px mb-15px">What I can offer</h1>
            <p class="md:text-size-24px text-size-20px md:px-10px w-[100%] max-w-[770px] md:leading-relaxed leading-normal mx-auto">
                Given enough time and effort, there is no job too large for me to handle. Here is a small offering of my services.
            </p>
        </div>

        <div class="flex gap-20px flex-wrap">
            <div class="services-item" v-scrollview v-for="(service, i) in services" :key="service.title" :style="`transition-delay: ${i * 200}ms;`">
                <Card :index="1" :icon="service.icon" :title="service.title" :description="service.description" />
            </div>
        </div>
        <div class="mt-30px text-center mb-50px">
            <div>
                <router-link to="/contact" class="bg-[var(--primary)] px-25px py-10px rounded-md text-[var(--background)] font-600 opacity-90 hover:opacity-100">
                    Get In Touch
                </router-link>
            </div>
        </div>
    </section>
</template>
<script>
import Card from './../../components/Card/Card.vue';
export default {
    components: { Card },
    setup() {
        const services = [
            {
                icon: `<i class='bx bx-window-alt'></i>`,
                title: 'Full Stack Development',
                description:
                    'I can work on both the front end and back end of web and mobile apps, ensuring a high-quality and seamless experience for the end user.',
            },
            {
                icon: "<i class='bx bx-data' ></i>",
                title: 'DevSecOps',
                description:
                    'I can help oversee the entire software development cycle, making sure resources are properly allocated and threat vectors minimized.',
            },
            {
                icon: "<i class='bx bx-edit' ></i>",
                title: 'Project Management',
                description:
                    'I have successfully led teams of 10+ people from project inception to completion. I can work with tight deadlines and deliver a successful product.',
            },
        ];
        return {
            services,
        };
    },
};
</script>
<style lang="postcss">
.services-item {
    flex: 1 1 300px;
}
</style>